<template>
  <div>
    <v-icon title="Adicionar Paletização" @click="adding = true">
      mdi-elevator-down
    </v-icon>
    <div v-if="adding">
      <v-dialog v-model="adding">
        <v-card>
          <v-card-title> Add Paletização </v-card-title>
          <v-card-text>
            <p>
              *Se os dados não estiverem corretos modifique-os diretamente no
              CTe
            </p>
            <div v-if="cte">
              <v-text-field
                label="Parceira"
                :value="cte.partners.name"
                disabled
              ></v-text-field>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="CTe"
                    v-model="cte.number"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="NFe"
                    v-model="cte.nf"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-text-field
                label="Remetente"
                v-model="cte.senders.name"
                disabled
              ></v-text-field>
              <v-text-field
                label="Destinatário"
                v-model="cte.recipients.name"
                disabled
              ></v-text-field>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="Volumes"
                    v-model="cte.qty"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="Peso"
                    v-model="cte.weigth"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="Valor NF"
                    v-model="cte.nf_value"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-form
                @submit.prevent="savePalletization()"
                ref="formPalettization"
              >
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Qtd PLT"
                      v-model="palletization.qty"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Valor"
                      v-model="palletization.value"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Data"
                      v-model="palletization.date"
                      type="date"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-btn type="submit" color="secondary" :loading="saving"
                  >Salvar</v-btn
                >
              </v-form>
            </div>
            <div v-if="!cte">
              <h1>Ops... Algo deu errado :(</h1>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import http from "@/modules/http";
export default {
  props: {
    cte: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      adding: false,
      saving: false,
      palletization: {
        cte_id: this.cte.id,
        qty: null,
        value: null,
        date: new Date().toISOString().slice(0, 10),
      },
    };
  },
  watch: {
    "palletization.qty"() {
      let qty = this.palletization.qty;
      if (!isNaN(qty)) {
        this.palletization.value = qty * 40;
      }
    },
  },
  methods: {
    savePalletization() {
      http
        .post("api/palletizations", this.palletization)
        .then(() => {
          this.adding = false;
          this.$toast.success('Salvo com sucesso');
          this.$emit("saved");
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error('Erro ao salvar');
        });
    },
  },
};
</script>
