<template>
	<div>
		<v-progress-circular size="100" indeterminate v-if="!commission"></v-progress-circular>
		<div v-if="commission">
			<h1>Comissão {{ commission.partner.name }}</h1>
			<h2>
				Fechamento do dia 
				<span>{{ commission.initial_date | moment("DD/MM/YYYY") }} </span>
				<span>até o dia </span>
				<span>{{ commission.final_date | moment("DD/MM/YYYY") }}.</span>
			</h2>

			<v-btn
				color="error"
				class="mx-1"
				title="Voltar"
				@click="$router.back()"
			>
				<v-icon>mdi-keyboard-return</v-icon>
			</v-btn>

			<v-btn
				color="warning"
				class="mx-1"
				@click="handleRestore"
			>
				<v-icon v-if="!restoring">mdi-restore</v-icon>
				<v-progress-circular v-if="restoring" indeterminate />
				<span class="ml-2">Estornar</span>
			</v-btn>

			<v-btn
				color="secondary"
				class="mx-1"
				@click="$router.push({ name: 'commissions.list' })"
			>
				<v-icon v-if="!restoring">mdi-clipboard-list</v-icon>
				<span class="ml-2">Listar</span>
			</v-btn>

			<print-commission :commission="commission" />

			<v-btn
				color="success"
				class="mx-1"
				@click="handleExport"
			>
				<v-icon>mdi-microsoft-excel</v-icon>
				<span class="ml-2">Exportar</span>
			</v-btn>

			<v-divider class="my-2"></v-divider>

			<h3>Resumo:</h3>

			<v-row>
				<v-col cols="12" md="6">
					<ul>
						<li>
							<span>Total de TDA:</span> <span>{{ $functions.moneyFormat(commission.total_tda) }}</span>
						</li>
						<li>
							<span>Total de TDE:</span> <span>{{ $functions.moneyFormat(commission.total_tde) }}</span>
						</li>
						
						<li>
							<span>Total de Taxas:</span> <span>{{ $functions.moneyFormat(commission.total_fees) }}</span>
						</li>
						<li>
							<span>Total de Descarga:</span> <span>{{ $functions.moneyFormat(commission.total_dischage) }}</span>
						</li>
						<li>
							<span>Total de Paletização:</span> <span>{{ $functions.moneyFormat(commission.total_palletization) }}</span>
						</li>
						<br><br>
					</ul>
				</v-col>
				<v-col cols="12" md="6">
					<ul>
						<li>
							<span>
								<strong>Total de Coletas:</strong>
							</span> 
							<span>
								<strong>{{ $functions.moneyFormat(commission.total_collects_commission) }}</strong>
							</span>
						</li>
						<li>
							<span>
								<strong>Total de Entregas:</strong>
							</span> 
							<span>
								<strong>{{ $functions.moneyFormat(commission.total_deliveries_commission) }}</strong>
							</span>
						</li>
						<li>
							<span>
								<strong>Total da comissão:</strong>
							</span> 
							<span>
								<strong>{{ $functions.moneyFormat(commission.total_commission) }}</strong>
							</span>
						</li>
					</ul>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import PrintCommission from '../components/PrintCommission';
  import { url } from '@/modules/http';

	export default {
		components: {
			PrintCommission,
		},
		mounted() {
			this.getCommission();
		},
		data() {
			return {
				commission: null,
				restoring: false,
			}
		},
		methods: {
			handleExport() {
				this.$http.get(`api/closed-commissions/export/${this.commission.id}`, {responseType: 'blob'})
					.then(response => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', `comissao_${this.commission.id}.xlsx`);
						document.body.appendChild(link);
						link.click();
					})
					.catch(err => {
						console.error(err);
						this.$toast.error('Erro ao baixar o arquivo');
					});
			},
			handleRestore() {
				if(confirm('Tem certeza que quer estornar esta comissão? Todos os cálculos teram de ser feitos novamente mais tarde.')){

					this.restoring = true;

					this.$http.delete(`api/commissions/${this.commission.id}`)
						.then(() => {
							this.$toast.success('Estornado!');
							this.$router.push(`/comissoes`);
							this.restoring = false;
						})
						.catch(err => {
							console.log(err);
							this.$toast.error('Erro ao estornar');
							this.restoring = false;
						});
				}
			},
			getCommission() {
				this.$http.get(`api/commissions/${this.$route.params.id}`)
					.then(({data}) => {
						this.commission = data;
					})
					.catch(err => console.log(err))
			}
		}
	}
</script>


<style lang="scss" scoped>
	ul {
		list-style-type: none;
		margin-top: 10px;

		li {
			background: #fff;
			box-shadow: 2px 2px 5px;
			margin-bottom: 2px;
			padding: 0 5px;
			border-radius: 5px;
			display: flex;
			justify-content: space-between;
		}
	}
</style>