<template>
  <div>
    <v-progress-linear
      color="red"
      indeterminate
      v-if="!delivery"
    ></v-progress-linear>

    <div v-if="delivery">
      <h1>
        Romaneio: {{ delivery.id }} - Motorista: {{ delivery.drivers.name }} -
        {{ delivery.date | moment("DD/MM/YYYY") }}
        <v-btn
          fab
          small
          @click="$refs.editDeliveryModalComponent.openModal()"
        >
          <v-icon color="warning">
            mdi-pencil
          </v-icon>
        </v-btn>
      </h1>

      <EditDeliveryModal @updated="getDelivery" ref="editDeliveryModalComponent" :delivery="delivery" />

      <div class="d-flex flex-wrap">
        <v-btn
          color="error"
          title="Voltar"
          class="ma-2"
          @click="$router.push('/romaneios-completo')"
        >
          <v-icon>
            mdi-keyboard-return
          </v-icon>
        </v-btn>
        <v-btn
          color="secondary"
          title="Recarregar"
          class="ma-2"
          @click="getRules()"
        >
          <v-icon>
            mdi-refresh
          </v-icon>
        </v-btn>
        <v-switch class="mx-1" label="Remetente" v-model="show.sender" />
        <v-switch class="mx-1" label="Destino" v-model="show.recipient" />
        <v-switch class="mx-1" label="Peso" v-model="show.weigth" />
        <v-switch class="mx-1" label="Cidade" v-model="show.city" />
      </div>

      <h3>Adicionar CTe</h3>

      <div class="d-flex">
        <!-- <print-delivery v-if="delivery" :delivery="delivery" /> -->

        <set-all-delivered
          @setDelivered="getRules()"
          :date="delivery.date"
          :ctes="delivery.delivery_ctes"
        />
      </div>

      <v-form :disabled="searching" @submit.prevent="addCte">
        <v-text-field
          label="CTe"
          append-outer-icon="mdi-check"
          @click:append-outer="addCte"
          v-model="cte"
          :disabled="searching"
          :loading="searching"
          ref="cteField"
        ></v-text-field>
      </v-form>

      <p v-if="finded.length > 1">{{ finded.length }} CTes encontrados</p>
      <v-data-table
        :items="finded"
        :headers="findedHeaders"
        v-if="finded.length > 1"
        @click:row="selectCte"
        disable-pagination
        hide-default-footer
        :item-class="findedClass"
      >
        <template v-slot:[`item.nf`]="{ item }">
          <span>{{ $functions.cutName(item.nf, 10) }}</span>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <span>{{ handleStatus(item) }}</span>
        </template>
      </v-data-table>

      <hr v-if="finded.length > 1" />

      <v-data-table
        dense
        disable-pagination
        hide-default-footer
        :items="delivery.delivery_ctes"
        :headers="filterHeader"
        :loading="loading"
        :item-class="rowColor"
      >
        <template v-slot:[`item.ctes.number`]="{ item }">
          <span :title="'NF:' + item.ctes.nf">
            <a :href="'/#/editar-cte/' + item.ctes.id" target="_blank">{{
              item.ctes.number
            }}</a>
          </span>
        </template>

        <template v-slot:[`item.ctes.senders.name`]="{ item }">
          <span :title="item.ctes.senders.name">{{
            $functions.cutName(item.ctes.senders.name)
          }}</span>
        </template>

        <template v-slot:[`item.ctes.recipients.name`]="{ item }">
          <span :title="item.ctes.recipients.name">{{
            $functions.cutName(item.ctes.recipients.name)
          }}</span>
        </template>

        <template v-slot:[`item.ctes.weigth`]="{ item }">
          <span>{{ item.ctes.weigth.toFixed(2) }} KG</span>
        </template>

        <template v-slot:[`item.ctes.freight_value`]="{ item }">
          <span>{{ $functions.moneyFormat(item.ctes.freight_value) }}</span>
        </template>

        <template v-slot:[`item.commission`]="{ item }">
          <span>{{
            $functions.moneyFormat(item.ctes.commission.toFixed(2))
          }}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex flex-wrap">
            <set-delivered-component @deliveryDateSaved="getDelivery()" :cte="item.ctes" />
            <v-icon
              :disabled="deleting || loading"
              color="red"
              @click="deleteDelivery(item)"
              v-if="!item.ctes.delivered"
              >mdi-delete</v-icon
            >
          </div>
        </template>

        <template v-slot:[`body.append`]="{}">
          <tr>
            <td>
              <strong>
                QTD: 
                {{ delivery.delivery_ctes.length }}
              </strong>
            </td>
            <td></td>
            <td v-if="show.sender"></td>
            <td v-if="show.recipient"></td>
            <td v-if="show.city"></td>
            <td v-if="show.weigth">
              <strong> {{ weigth_total.toFixed(2) }} KG </strong>
            </td>
            <td>
              <strong>
                {{ $functions.moneyFormat(freight_total.toFixed(2)) }}
              </strong>
            </td>
            <td>
              <strong>
                {{ $functions.moneyFormat(commission_total.toFixed(2)) }}
              </strong>
            </td>
            <td></td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
// import PrintDelivery from "../components/PrintDelivery";
import http from "@/modules/http";
import moment from "moment";
import SetAllDelivered from "../components/setAllDelivered.vue";
import SetDeliveredComponent from "../../ctes/components/setDeliveredComponent.vue";
import EditDeliveryModal from '../components/EditDeliveryModal';

export default {
  components: {
    // PrintDelivery,
    SetAllDelivered,
    SetDeliveredComponent,
    EditDeliveryModal
  },
  mounted() {
    this.getRules();
  },
  computed: {
    filterHeader() {
      let newHeader = [
        { text: "CTe", value: "ctes.number" },
        { text: "Parceira", value: "ctes.partners.name" },
      ];

      if (this.show.sender) {
        newHeader.push({ text: "Remetente", value: "ctes.senders.name" });
      }

      if (this.show.recipient) {
        newHeader.push({ text: "Destino", value: "ctes.recipients.name" });
      }

      if (this.show.city) {
        newHeader.push({
          text: "Cidade",
          value: "ctes.recipients.cities.name",
        });
      }

      if (this.show.weigth) {
        newHeader.push({ text: "Peso", value: "ctes.weigth" });
      }

      newHeader.push({ text: "Frete", value: "ctes.freight_value" });
      newHeader.push({ text: "Comissão", value: "commission" });
      newHeader.push({ text: "Ações", value: "actions" });

      return newHeader;
    },
  },
  data() {
    return {
      show: {
        sender: true,
        recipient: true,
        city: true,
        weigth: true,
      },
      rules: [],
      deleting: false,
      loading: false,
      searching: false,
      cte: "",
      delivery: null,
      freight_total: 0,
      weigth_total: 0,
      commission_total: 0,
      finded: [],
      findedHeaders: [
        { text: "CTe", value: "number" },
        { text: "NF", value: "nf" },
        { text: "Status", value: "status" },
      ],
      headers: [
        { text: "ID", value: "id" },
        { text: "CTe", value: "ctes.number" },
        { text: "Parceira", value: "ctes.partners.name" },
        { text: "Remetente", value: "ctes.senders.name" },
        { text: "Destinatário", value: "ctes.recipients.name" },
        { text: "Peso", value: "ctes.weigth" },
        { text: "Frete", value: "ctes.freight_value" },
        { text: "Comissão", value: "commission" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
  methods: {
    handleStatus(cte) {
      if (cte.delivered) {
        return "CTe entregue em " + moment(cte.delivered).format("DD/MM/YYYY");
      } else if (cte.deliveries.length > 0) {
        return cte.deliveries[0].delivery_id === this.delivery.id
          ? "Em rota de entrega neste romaneio"
          : "Em rota de entrega no romaneio " + cte.deliveries[0].delivery_id;
      }
    },

    findedClass(row) {
      if (row.delivered) {
        return "disabled light-green";
      } else if (row.deliveries.length > 0) {
        return "red";
      } else {
        return "pointer";
      }
    },

    rowColor(row) {
      if(row.ctes.occurrences && row.ctes.occurrences.length > 0) {
        let hasOccurrence = false;

        row.ctes.occurrences.forEach(occurrence => {
          if(occurrence.closed === 0) {
            hasOccurrence = true;
          }
        });

        if(hasOccurrence) {
          return "text-warning";
        }
      }
      return row.ctes.delivered ? "light-green": "";
    },

    deleteDelivery(delivery) {
      if (confirm("Tem certeza que deseja remover esse CTe do romaneio?")) {
        this.deleting = true;
        http
          .delete("api/delivery-ctes/" + delivery.id)
          .then(() => {
            this.deleting = false;
            this.$side({
              type: "success",
              msg: "Removido com sucesso",
              duration: 4000,
            });
            this.getDelivery();
          })
          .catch((err) => {
            this.deleting = false;
            this.$side({
              type: "error",
              msg: "Erro ao remover",
              duration: 4000,
            });
            console.log(err);
          });
      }
    },
    selectCte(e) {
      this.createDeliveryCte(
        {
          delivery_id: this.delivery.id,
          cte_id: e.id,
        },
        e
      );
    },
    verifyIsHoliday(delivery) {
      if(delivery.ctes.recipients.cities.holidays.length < 1) {
        return;
      }

      delivery.ctes.recipients.cities.holidays.forEach(holiday => {
        let deliveryDate = this.delivery.date.slice(5,7)
        let deliveryMonth = this.delivery.date.slice(8,10)

        let holidayDate = holiday.date.slice(5,7)
        let holidayMonth = holiday.date.slice(8,10)

        if(
          deliveryDate === holidayDate &&
          deliveryMonth === holidayMonth
        ) {
          alert(`
            *** Atenção ***
            É feriado nesta cidade no dia do romaneio!
          `);
        }
      });
    },
    createDeliveryCte(data, cte) {
      if (cte.delivered) {
        this.$side({
          type: "error",
          msg: "Este CTe já foi entregue!",
          duration: 4000,
        });

        return;
      }

      if (cte.deliveries.length > 0) {
        this.$side({
          type: "error",
          msg: "Este CTe já tem romaneio!",
          duration: 4000,
        });

        return;
      }

      this.loading = true;

      let has = false;

      this.delivery.delivery_ctes.forEach((e) => {
        if (e.ctes.number === cte.number) {
          has = true;
        }
      });

      if (has) {
        this.loading = false;
        this.$side({
          type: "error",
          msg: "CTe já está no romaneio!",
          duration: 6000,
        });
      } else {
        if(cte.recipients.obs) {
          alert(`
            *** ATENÇÃO ***
            ${cte.recipients.obs}
          `)
        }

        http
          .post("api/delivery-ctes", data)
          .then((res) => {
            this.loading = false;

            this.verifyIsHoliday(res.data);

            this.getDelivery();

            this.$refs.cteField.focus();
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            if (err.response.status === 406) {
              this.$toast.error('CTe já tem romaneio!');
            } else if (err.response.status === 422) {
              alert(`
                *** ATENÇÃO ***
                CTe não está liberado para entrega
              `);
            } else {
              this.$toast.error('Erro ao adicionar cte');
            }
          });
      }
    },
    addCte() {
      this.searching = true;
      http.get("api/ctes-filter?cte=" + this.cte).then((res) => {
        this.searching = false;
        if (res.data.length > 0) {
          if (res.data.length > 1) {
            //console.log(res.data)
            this.finded = res.data;
          } else {
            this.createDeliveryCte(
              {
                delivery_id: this.delivery.id,
                cte_id: res.data[0].id,
              },
              res.data[0]
            );
          }
        } else {
          this.$side({
            type: "error",
            msg: "CTe não encontrado!",
            duration: 4000,
          });
        }
      });
    },
    handleCommissionVariation(cte) {
      if(cte.partners.variations.length < 2) {
        return cte.partners.variations[0];
      }

      cte.partners.variations.forEach(variation => {
        if(variation.city_id === cte.recipients.city_id) {
          return variation;
        }
      });

      return cte.partners.variations[0];
    },
    handleFees(cte, variation) {
      let tda = 0;
      let tde = 0;
      let palletization = 0;
      let discharge = parseFloat(cte.discharge) || 0;
      let fees = parseFloat(cte.fees) || 0;
      
      if(cte.tda > 0) {
        tda = (cte.tda * variation.commission_tda) / 100;
      }

      if(cte.tde > 0) {
        tde = (cte.tde * variation.commission_tde) / 100;
      }

      if(cte.palletizations.length > 0) {
        palletization = parseFloat(cte.palletizations[0].value);
      }
      
      return tda + tde + palletization + discharge + fees;
    },
    discountIcms(cte) {
      let discount = 0;

      if(cte.icms_value) {
        discount = parseFloat(cte.icms_value);
      } else if(cte.icms) {
        discount = (cte.freight_value * cte.icms) / 100;
      } else {
        discount = (cte.freight_value * 12) / 100;
      }
      return discount;
    },
    calCommission(cte) {
      if(cte.commission_value) {
        return parseFloat(cte.commission_value);
      }

      let variation = this.handleCommissionVariation(cte);

      if(!variation) {
        return 0;
      }

      // console.log(variation);

      if(variation.icms_discount) {
        cte.freight_without_icms = parseFloat(cte.freight_value) - this.discountIcms(cte);
      }

      let freightToCalculate = cte.freight_without_icms || cte.freight_value;

      if(variation.tda_discount && cte.tda) {
        freightToCalculate -= parseFloat(cte.tda);
      }

      if(variation.tde_discount && cte.tde) {
        freightToCalculate -= parseFloat(cte.tde);
      }

      let commission = (freightToCalculate * variation.commission_percentage) / 100;

      if(commission < variation.min_freight) {
        commission = parseFloat(variation.min_freight);
      }


      commission += this.handleFees(cte, variation);

      return commission;
    },
    calcTotal() {
      this.freight_total = 0;
      this.weigth_total = 0;
      this.commission_total = 0;

      this.delivery.delivery_ctes.forEach((e) => {
        this.freight_total += parseFloat(e.ctes.freight_value);
        this.weigth_total += parseFloat(e.ctes.weigth);

        e.ctes.commission = this.calCommission(e.ctes);

        this.commission_total += parseFloat(e.ctes.commission);
      });
    },
    getRules() {
      this.getDelivery();
    },
    getDelivery() {
      this.cte = "";
      this.finded = [];
      this.loading = true;
      http
        .get("api/deliveries/" + this.$route.params.id)
        .then((res) => {
          this.loading = false;
          this.delivery = res.data;
          this.calcTotal();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$side({
            type: "error",
            msg: "Erro de conexão com o servidor",
            duration: 4000,
          });
        });
    },
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
.pointer:hover {
  box-shadow: 0 0 5px inset;
}
.disabled {
  opacity: 0.7;
}
</style>
